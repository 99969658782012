<template>
  <v-container align="center" justify="center">
    <v-row class="mx-auto py-4">
      <v-col cols="12">
        <h1>
          <router-link :to="{ name: 'AssessmentCenter' }"
            >Assessment center</router-link
          >
          | <span class="title"> Vehicle Checkout</span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-auto" cols="12" md="12" xl="11">
        <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
          <card-title
            :icon="require('@/assets/img/assesment-center/accident_icon.png')"
            >Vehicle Checkout</card-title
          >
          <v-card-text class="">
            <div v-if="isBookings">
              <div class="tw-flex tw-flex-row tw-justify-end">
                <div class="tw-w-full sm:tw-w-1/3 md:tw-w-1/4 tw-pb-3">
                  <v-text-field
                    v-model="search"
                    underlined
                    append-icon="mdi-magnify"
                    label="Search"
                    placeholder="Reg No. e.g KAA 001A"
                  ></v-text-field>
                </div>
              </div>
              <v-data-table
                :headers="headers"
                :items="bookings"
                :search.sync="search"
                :loading="loadingTable"
                :page.sync="pagination.pageNumber"
                :items-per-page.sync="pagination.perPage"
                :server-items-length="pagination.totalItems"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :footer-props="{
                  itemsPerPageOptions: [10, 20, 30, 40, 50],
                }"
              >
                <template v-slot:[`footer.page-text`]="{}"></template>
                <template v-slot:item.biddingTemplate.make="{ item }">
                  {{ item.biddingTemplate.make }}
                  {{ item.biddingTemplate.model }}
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <div>{{ item.createdAt | formatToHuman }}</div>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip
                    v-if="item.status === 'CHECKED_OUT'"
                    color="success"
                    small
                  >
                    {{ item.status | formatStatus }}
                  </v-chip>
                  <v-chip v-else color="primary" small>
                    {{ item.status | formatStatus }}</v-chip
                  >
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn v-if="item.status !== 'CHECKED_OUT'" v-model="item.action" color="secondary"
                    small
                    :to="{
            name: 'CheckoutForm',
            params: { bookingId: item.id, reg: item.vehicleInfo.registrationNumber },
          }"
                    >Checkout
                    </v-btn>
                  <a v-else href="#" :key="item.action" @click="handleCheckoutReportDialog(item)"><v-icon class="pr-2"
                            color="primary" small>mdi-eye</v-icon>
                          View</a>
                </template>
              </v-data-table>
            </div>
            <div
              v-else
              class="
                tw-py-4
                md:tw-py-14
                tw-flex tw-items-center tw-justify-center tw-flex-col
              "
            >
              <img
                :src="require('@/assets/img/assesment-center/no-car.png')"
                class="tw-h-16 md:tw-h-28"
                alt="no car"
              />
              <p
                class="tw-w-full md:tw-w-1/3 tw-text-xs tw-p-3 tw-text-justify"
              >
                There are no bookings ready for checkout, please head to the
                <span class="c-blue-text tw-font-bold"
                  >previous module in the action panel</span
                >
              </p>
              <v-btn
                :to="{ name: 'AssessmentCenter' }"
                class="tw-w-full md:tw-w-1/3"
                color="warning"
                >Go To Action Panel</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row></v-row>

    <v-dialog v-model="checkoutReportDialog" width="1000">
      <v-card style="border: none">
        <div class="c-bg-blue tw-p-4 tw-px-8">
          <h2 class="c-white-text tw-text-xl">Checkout Report</h2>
        </div>

        <div class="tw-p-8">
          <div>
            <h3 class="c-gray-text tw-mb-3 tw-text-lg">Client Details</h3>

            <v-divider></v-divider>

            <div
              class="
                tw-grid tw-grid-cols-1
                md:tw-grid-cols-2
                tw-gap-3
                md:tw-gap-8
                tw-my-6
              "
            >
              <div class="tw-flex tw-flex-col tw-gap-3">
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold"
                    >Assessment No.</span
                  >
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'id')
                  }}</span>
                </div>
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold">Claim No.</span>
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'biddingTemplate.claimNumber')
                  }}</span>
                </div>
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold">Insured Name</span>
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'clientInfo.name')
                  }}</span>
                </div>
              </div>

              <div class="tw-flex tw-flex-col tw-gap-3">
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold">Date Created</span>
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'createdAt') | formatToHuman
                  }}</span>
                </div>
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold">Policy No.</span>
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'policyInfo.policyNumber')
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3 class="c-gray-text tw-mb-3 tw-text-lg">Vehicle Details</h3>

            <v-divider></v-divider>

            <div
              class="
                tw-grid tw-grid-cols-1
                md:tw-grid-cols-3
                tw-gap-3
                md:tw-gap-8
                tw-my-6
              "
            >
              <div class="tw-flex tw-flex-col tw-gap-3">
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold"
                    >Registration No.</span
                  >
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'vehicleInfo.registrationNumber')
                  }}</span>
                </div>
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold"
                    >Vehicle Color</span
                  >
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'biddingTemplate.color')
                  }}</span>
                </div>
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold">Engine No.</span>
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'biddingTemplate.engineNumber')
                  }}</span>
                </div>
              </div>

              <div class="tw-flex tw-flex-col tw-gap-3">
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold">Make</span>
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'biddingTemplate.make')
                  }}</span>
                </div>
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold">YOM</span>
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'biddingTemplate.yearOfManufacture')
                  }}</span>
                </div>
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold">Chassis No.</span>
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'vehicleInfo.chassisNumber')
                  }}</span>
                </div>
              </div>

              <div class="tw-flex tw-flex-col tw-gap-3">
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold">Model</span>
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'biddingTemplate.model')
                  }}</span>
                </div>
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold">Vehicle Type</span>
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'biddingTemplate.bodyType')
                  }}</span>
                </div>
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold">Mileage</span>
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'biddingTemplate.mileage')
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3 class="c-gray-text tw-mb-3 tw-text-lg">Checkout Details</h3>

            <v-divider></v-divider>

            <div
              class="
                tw-grid tw-grid-cols-1
                md:tw-grid-cols-3
                tw-gap-3
                md:tw-gap-8
                tw-mt-6
              "
            >
              <div>
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold"
                    >Checkedout Date</span
                  >
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'checkoutAt') | formatToHuman
                  }}</span>
                </div>
              </div>
              <div>
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold"
                    >Analysis Date</span
                  >
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'analysedAt') | formatToHuman
                  }}</span>
                </div>
              </div>
              <div>
                <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                  <span class="c-gray-text tw-font-semibold">Analysed By</span>
                  <span class="c-gray-text">{{
                    scanObject(viewItem, 'analysedBy')
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import bookingsMixin from '@/mixins/bookingsMixin'
//import {formatStatus} from '@/utils/helpers'
import { formatToHuman } from '@/utils/time'
import { formatStatus } from '../../../utils/helpers'

export default {
  components: { CardTitle },
  mixins: [bookingsMixin],
  data() {
    return {
      loadingTable: true,
      headers: [
        { text: 'No.', value: 'id', sortable: false },
        { text: 'Customer', value: 'clientInfo.name', sortable: false },
        { text: 'Make/Model', value: 'biddingTemplate.make', sortable: false },
        {
          text: 'Reg No.',
          value: 'vehicleInfo.registrationNumber',
          sortable: false,
        },
        { text: 'Date', value: 'createdAt', sortable: false },
        { text: 'Phone No', value: 'clientInfo.phoneNumber', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Actions', value: 'action', align: 'center', sortable: false },
      ],
      bookings: [],
      fetchBookingsUrl: '/motor-assessment/api/booking/checkout/all',
      checkoutReportDialog: false,
      viewItem: null,
    }
  },
  computed: {
    isBookings() {
      return this.bookings.length > 0 || this.loadingTable
    },
  },
  mounted() {
    this.fetchCheckout = true
    this.fetchBookings()
  },
  methods: {
    handleCheckoutReportDialog(item) {
      this.checkoutReportDialog = true
      this.viewItem = item
    },
  },
  filters: {
    formatToHuman,
    formatStatus,
  },
}
</script>
